<template>
  <Dialog dense :common-dialog="itemDialog" :dialog-width="dialog_width">
    <template v-slot:title>
      Select {{ itemTitle }}s
      <v-icon class="mx-2 color-custom-blue" small>mdi-record</v-icon> Line Item
    </template>
    <template v-slot:body>
      <v-container fluid>
        <v-row class="mx-4">
          <v-col v-if="categories.length" md="12" class="alphabets-filter">
            <div class="alphabets-scrollable">
              <v-btn
                depressed
                :disabled="loading"
                v-on:click="search_line_items(null, 'category')"
                :class="{
                  'cyan--text': null == category,
                  'cyan white--text': null != category,
                }"
                class="custom-bold-button"
                small
                >All</v-btn
              >
              <v-btn
                depressed
                :disabled="loading"
                v-for="(row, index) in categories"
                v-on:click="search_line_items(row.value, 'category')"
                :class="{
                  'cyan--text': row.value == category,
                  'cyan white--text': row.value != category,
                }"
                class="custom-bold-button"
                small
                :key="index"
                >{{ row.text }}</v-btn
              >
            </div>
          </v-col>
          <v-col v-if="alphabets.length" md="12" class="alphabets-filter">
            <div class="alphabets-scrollable">
              <v-btn
                depressed
                :disabled="loading"
                v-on:click="search_line_items(null, 'alphabet')"
                :class="{
                  'cyan--text': null == alphabet,
                  'cyan white--text': null != alphabet,
                }"
                class="custom-bold-button"
                small
                >All</v-btn
              >
              <v-btn
                depressed
                :disabled="loading"
                v-for="(row, index) in alphabets"
                v-on:click="search_line_items(row, 'alphabet')"
                :class="{
                  'cyan--text': row == alphabet,
                  'cyan white--text': row != alphabet,
                }"
                class="custom-bold-button"
                small
                :key="index"
                >{{ row }}</v-btn
              >
            </div>
          </v-col>
          <v-col md="12">
            <v-layout>
              <v-flex
                md6
                class="font-weight-700 font-size-17 color-custom-blue"
              >
                <v-btn
                  small
                  :disabled="loading"
                  depressed
                  :class="{
                    'green white--text': show_selected,
                    'white green--text': !show_selected,
                  }"
                  class="font-size-17 font-weight-700"
                  v-on:click="show_selected = !show_selected"
                  >{{ selected_count() }}</v-btn
                >
                {{ itemTitle }}s Selected
              </v-flex>
              <v-flex md6>
                <v-text-field
                  v-model="search"
                  label="Search"
                  placeholder="Start typing then press enter to search..."
                  dense
                  filled
                  solo
                  flat
                  color="cyan"
                  :disabled="loading"
                  append-icon="mdi-magnify"
                  clearable
                  v-on:click:clear="
                    search = null;
                    search_line_items(search, 'search');
                  "
                  v-on:keyup.enter="search_line_items(search, 'search')"
                  v-on:click:append="search_line_items(search, 'search')"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <div class="custom-line-item-template">
              <table width="100%" class="line-item-multiple-table">
                <thead>
                  <tr>
                    <th height="40px" align="center" width="3%">
                      <v-checkbox
                        hide-details
                        class="my-0 py-0 d-inline-block"
                        color="cyan"
                        v-model="check_all"
                        :disabled="loading"
                        v-on:change="select_all($event)"
                      ></v-checkbox>
                    </th>
                    <th height="40px" width="51%">{{ itemTitle }}</th>
                    <th height="40px" width="10%" align="right">Quantity</th>
                    <th height="40px" width="18%" align="right">Rate</th>
                    <th height="40px" width="18%" align="right">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="loading">
                    <tr>
                      <td colspan="5">
                        <div
                          class="my-10 mx-5 d-flex align-center justify-center"
                        >
                          <v-progress-circular
                            :width="3"
                            color="cyan"
                            indeterminate
                          ></v-progress-circular>
                          <p class="font-size-16 mx-5 my-0 font-weight-600">
                            Loading...
                          </p>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-else-if="line_items.length">
                    <tr
                      v-for="(row, index) in line_items"
                      :key="`line-item-${index}`"
                      class="line-item-row"
                    >
                      <td valign="top" align="center" width="3%">
                        <br />
                        <v-checkbox
                          hide-details
                          class="mt-2 mb-0 py-0 d-inline-block"
                          color="cyan"
                          v-model="row.status"
                          v-on:change="update_items($event, row.product_id)"
                        ></v-checkbox>
                      </td>
                      <td valign="top" width="51%">
                        <br />
                        <v-layout>
                          <v-flex
                            class="text-center"
                            v-if="row.product_image"
                            md2
                          >
                            <v-avatar size="100">
                              <v-img contain :src="row.product_image"></v-img>
                            </v-avatar>
                          </v-flex>
                          <v-flex>
                            <v-text-field
                              dense
                              filled
                              hide-details
                              color="cyan"
                              solo
                              flat
                              readonly
                              :placeholder="itemTitle"
                              v-model="row.product"
                            >
                              <template v-slot:prepend-inner>
                                <v-chip
                                  small
                                  label
                                  class="custom-status font-weight-600 custom-grey-border text-uppercase"
                                  text-color="white"
                                  color="cyan"
                                >
                                  {{ row.barcode }}
                                </v-chip>
                              </template>
                              <template v-slot:append>
                                <v-chip
                                  small
                                  label
                                  class="ml-2 custom-status font-weight-600 custom-grey-border text-uppercase"
                                  text-color="white"
                                  color="cyan"
                                >
                                  {{ get_product_type(row.product_type) }}
                                </v-chip>
                              </template>
                            </v-text-field>
                            <v-textarea
                              auto-grow
                              dense
                              filled
                              readonly
                              color="cyan"
                              label="Description"
                              placeholder="Description"
                              solo
                              flat
                              hide-details
                              row-height="25"
                              v-model="row.description"
                            ></v-textarea>
                            <v-checkbox
                              v-if="!isPackage"
                              hide-details
                              class="my-2 py-0 d-inline-block"
                              color="cyan"
                              :readonly="!row.status"
                              label="Is Optional?"
                              :true-value="1"
                              :false-value="0"
                              v-model="row.is_optional"
                              v-on:change="update_value(index)"
                            ></v-checkbox>
                          </v-flex>
                        </v-layout>
                        <br />
                      </td>
                      <td valign="top" width="10%" align="right">
                        <br />
                        <v-text-field
                          dense
                          filled
                          hide-details
                          color="cyan"
                          solo
                          flat
                          :readonly="!row.status"
                          type="number"
                          :suffix="row.uom"
                          v-on:keypress="is_number($event)"
                          placeholder="Quantity"
                          v-model="row.quantity"
                          v-on:change="update_value(index)"
                          v-on:click="update_value(index)"
                          v-on:keyup="update_value(index)"
                        ></v-text-field>
                      </td>
                      <td valign="top" width="18%" align="right">
                        <br />
                        <v-text-field
                          dense
                          filled
                          hide-details
                          color="cyan"
                          solo
                          flat
                          :readonly="!row.status"
                          prefix="$"
                          type="number"
                          v-on:keypress="is_number($event)"
                          placeholder="Rate"
                          v-model="row.rate"
                          v-on:change="update_value(index)"
                          v-on:click="update_value(index)"
                          v-on:keyup="update_value(index)"
                        ></v-text-field>
                        <br />
                        <p
                          class="w-100 my-2 text-left font-size-16 font-weight-500"
                        >
                          Supplier Cost:&nbsp;&nbsp;<label
                            class="m-0 p-0 font-weight-600"
                            >{{ formatMoney(row.company_cost) }}</label
                          >
                        </p>
                        <p
                          class="w-100 my-2 text-left font-size-16 font-weight-500"
                        >
                          Price:&nbsp;&nbsp;<label
                            class="m-0 p-0 font-weight-600"
                            >{{ formatMoney(row.rate) }}</label
                          >
                        </p>
                        <p
                          class="w-100 my-2 text-left font-size-16 font-weight-500"
                        >
                          Profit:&nbsp;&nbsp;<label
                            class="m-0 p-0 font-weight-600"
                            >{{ get_profit(index) }}</label
                          >
                        </p>
                        <p
                          class="w-100 my-2 text-left font-size-16 font-weight-500"
                        >
                          Net Price:&nbsp;&nbsp;<label
                            class="m-0 p-0 font-weight-600"
                            >{{ formatMoney(row.net_price) }}</label
                          >
                        </p>
                      </td>
                      <td valign="top" width="18%" align="right" class="pr-2">
                        <br />
                        <v-text-field
                          dense
                          filled
                          hide-details
                          color="cyan"
                          solo
                          flat
                          readonly
                          prefix="$"
                          type="number"
                          v-on:keypress="is_number($event)"
                          placeholder="Total"
                          v-model="row.total"
                        >
                        </v-text-field>
                        <br />
                        <p
                          class="w-100 my-2 text-left font-size-16 font-weight-500"
                        >
                          Supplier Cost:&nbsp;&nbsp;<label
                            class="m-0 p-0 font-weight-600"
                            >{{ get_total_company_cost(index) }}</label
                          >
                        </p>
                        <p
                          class="w-100 my-2 text-left font-size-16 font-weight-500"
                        >
                          Price:&nbsp;&nbsp;<label
                            class="m-0 p-0 font-weight-600"
                            >{{ get_total_charges(index) }}</label
                          >
                        </p>
                        <p
                          class="w-100 my-2 text-left font-size-16 font-weight-500"
                        >
                          Profit:&nbsp;&nbsp;<label
                            class="m-0 p-0 font-weight-600"
                            >{{ get_total_profit(index) }}</label
                          >
                        </p>
                        <p
                          class="w-100 my-2 text-left font-size-16 font-weight-500"
                        >
                          Net Price:&nbsp;&nbsp;<label
                            class="m-0 p-0 font-weight-600"
                            >{{ get_net_price(index) }}</label
                          >
                        </p>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="5">
                        <p
                          class="m-0 my-5 row-not-found text-center font-weight-500 font-size-16"
                        >
                          <img
                            :src="$assetURL('media/error/empty.png')"
                            class="row-not-found-image"
                          />
                          Uhh... There are no
                          <span class="text-lowercase">{{ itemTitle }}</span>
                          at the moment.
                        </p>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:action>
      <v-btn
        v-on:click="close_dialog()"
        :disabled="loading"
        class="mx-2 custom-grey-border custom-bold-button"
        depressed
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="loading || !selected_line_items.length"
        class="mx-2 custom-bold-button white--text"
        color="cyan"
        v-on:click="emit_value()"
      >
        Save
      </v-btn>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "@/view/pages/partials/Dialog";
import CommonMixin from "@/core/plugins/common-mixin";
import { QUERY } from "@/core/services/store/request.module";
import ObjectPath from "object-path";

export default {
  name: "line-item-search-dialog",
  model: {
    prop: "value",
    event: "input",
  },
  components: {
    Dialog,
  },
  mixins: [CommonMixin],
  props: {
    value: {
      type: Array,
      default() {
        return new Array();
      },
    },
    updateMode: {
      type: Boolean,
      default: false,
    },
    itemDialog: {
      type: Boolean,
      default: false,
    },
    itemTitle: {
      type: String,
      default: "Item",
    },
    itemType: {
      type: String,
      default: "service",
    },
    isPackage: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    itemDialog(param) {
      if (param) {
        this.selected_line_items = this.lodash.cloneDeep(this.value);
        console.log({ selected_line_items: this.selected_line_items });
        this.get_line_items();
      }
    },
  },
  data() {
    return {
      check_all: false,
      line_items: [],
      selected_line_items: [],
      loading: true,
      show_selected: false,
      search: null,
      category: null,
      categories: [],
      alphabet: null,
      alphabets: [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
      ],
    };
  },
  methods: {
    get_product_type(type) {
      if (type == "service") {
        return "Additional Work";
      } else {
        return type;
      }
    },
    get_profit(index) {
      const line_item = this.lodash.cloneDeep(this.line_items[index]);
      const profit = line_item.rate - line_item.company_cost;
      return this.formatMoney(profit);
    },
    get_total_company_cost(index) {
      const line_item = this.lodash.cloneDeep(this.line_items[index]);
      const company_cost = line_item.company_cost * line_item.quantity;
      return this.formatMoney(company_cost);
    },
    get_total_charges(index) {
      const line_item = this.lodash.cloneDeep(this.line_items[index]);
      const charges = line_item.rate * line_item.quantity;
      return this.formatMoney(charges);
    },
    get_net_price(index) {
      const line_item = this.lodash.cloneDeep(this.line_items[index]);
      const charges = line_item.net_price * line_item.quantity;
      return this.formatMoney(charges);
    },
    get_total_profit(index) {
      const line_item = this.lodash.cloneDeep(this.line_items[index]);
      const profit =
        (line_item.rate - line_item.company_cost) * line_item.quantity;
      return this.formatMoney(profit);
    },
    update_value(index) {
      if (this.line_items[index]) {
        this.line_items[index].total =
          this.line_items[index].rate * this.line_items[index].quantity;
        this.$nextTick(() => {
          const line_item = this.lodash.cloneDeep(this.line_items[index]);
          const item_index = this.lodash.findIndex(this.selected_line_items, {
            product_id: line_item.product_id,
          });
          if (item_index >= 0) {
            this.selected_line_items[item_index] = line_item;
          }
        });
      }
    },
    selected_count() {
      return this.selected_line_items.length;
    },
    close_dialog() {
      ObjectPath.set(this, "search", null);
      ObjectPath.set(this, "alphabet", null);
      ObjectPath.set(this, "category", null);
      ObjectPath.set(this, "loading", true);
      ObjectPath.set(this, "check_all", false);
      ObjectPath.set(this, "show_selected", false);
      ObjectPath.set(this, "categories", []);
      ObjectPath.set(this, "line_items", []);
      ObjectPath.set(this, "selected_line_items", []);
      this.$emit("close", true);
    },
    emit_value() {
      this.$emit("input", this.selected_line_items);
      this.$emit("change", this.selected_line_items);
      this.$nextTick(() => {
        this.close_dialog();
      });
    },
    update_items(status, product_id) {
      const index = this.lodash.findIndex(this.selected_line_items, {
        product_id,
      });

      if (status && index < 0) {
        const row = this.lodash.find(this.line_items, { product_id });
        this.selected_line_items.push(row);
      } else {
        ObjectPath.del(this.selected_line_items, index);
      }

      this.$nextTick(() => {
        this.check_all = this.lodash.every(this.line_items, ["status", true]);
      });
    },
    init_selection() {
      if (this.selected_line_items.length > 0) {
        for (let i = 0; i < this.selected_line_items.length; i++) {
          const index = this.lodash.findIndex(this.line_items, {
            product_id: this.selected_line_items[i].product_id,
          });
          if (index >= 0) {
            const selected = this.lodash.cloneDeep(this.selected_line_items[i]);
            ObjectPath.set(this.line_items, index, selected);
          }
        }
      }
    },
    search_line_items(value, type) {
      ObjectPath.set(this, type, value);
      ObjectPath.set(this, "loading", true);
      ObjectPath.set(this, "check_all", false);
      ObjectPath.set(this, "line_items", []);
      this.$nextTick(() => {
        this.get_line_items();
      });
    },
    get_line_items() {
      this.$store
        .dispatch(QUERY, {
          url: "line-item-services",
          data: {
            type: this.itemType,
            search: this.search,
            alphabet: this.alphabet,
            category: this.category,
          },
        })
        .then((response) => {
          this.line_items = ObjectPath.get(response, "data.line_items", []);
          this.categories = ObjectPath.get(response, "data.categories", []);
          this.$nextTick(() => {
            this.init_selection();
            if (this.line_items.length > 0) {
              this.check_all = this.lodash.every(this.line_items, [
                "status",
                true,
              ]);
            }
          });
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update_all_rows(status, product_id) {
      const index = this.lodash.findIndex(this.selected_line_items, {
        product_id,
      });

      if (status) {
        if (index < 0) {
          const row = this.lodash.find(this.line_items, { product_id });
          this.selected_line_items.push(row);
        }
      } else {
        if (index >= 0) {
          ObjectPath.del(this.selected_line_items, index);
        }
      }

      this.$nextTick(() => {
        this.check_all = this.lodash.every(this.line_items, ["status", true]);
      });
    },
    select_all(param) {
      if (this.line_items.length > 0) {
        for (let i = 0; i < this.line_items.length; i++) {
          this.line_items[i].status = param;
          this.update_all_rows(param, this.line_items[i].product_id);
        }
      }
    },
  },
  beforeMount() {},
  computed: {
    dialog_width() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 80);
    },
  },
};
</script>
